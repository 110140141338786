import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import VZev from "../components/Applications/vZEV";
import { graphql } from "gatsby";
import { Seo } from "../components/App/SEO";

const VZEVPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("vZEV")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Anwendungen")}
      />
      <VZev />
      {/*<RelatedServices />*/}
      <Footer />
    </Layout>
  );
};

export default VZEVPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Zusammenschluss zum virtuellen Eigenverbrauch (vZEV)"
    description="Eigentümern von Solaranlagen profitieren am besten von Ihrer PV-Anlage, wenn sie einen ZEV bilden. Warum, wie und wann erfahren Sie bei Blockstrom"
  />
);
